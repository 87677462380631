<template>
    <section class="pt-2">
        <div class="row mx-0 py-3">
            <div class="col px-0 bg-white br-8 py-4">
                <div class="row align-items-center mx-0">
                    <div class="col-auto text-general f-18 f-500">
                        Retiros sin confirmar
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Hace más de una semana
                            <b class="ml-3">
                                {{ masUnaSemana }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Hace una semana
                            <b class="ml-3">
                                {{ haceUnaSemana }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Ayer
                            <b class="ml-3">
                                {{ ayer }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Hoy
                            <b class="ml-3">
                                {{ hoy }}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div v-if="dataTable.length" class="col-12 px-0">
                <tabla-general alto="calc(100vh - 511px)" :data="dataTable" :activar-seleccion="true" @seleccion="abrirModal">
                    <template slot="cabecera-izquierda">
                        <p class="col-auto py-3 text-general f-18 f-600">Lista de retiros de productos desde cedis</p>
                    </template>
                    <template slot="adicionales-derecha">
                        <template>
                            <el-table-column label="" class-name="f-16 text-general text-center" width="80">
                                <template slot-scope="{ row }">
                                    <i class="f-20 text-orange" :class="{ 'icon-format-list-checks' : row.estado == 2}" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="tipo" label="Tipo" class-name="f-16 text-general text-center" width="125" />
                            <el-table-column prop="producto_desc" label="Producto" class-name="f-16 text-general text-left" width="500" />
                            <el-table-column label="Cantidad" class-name="f-16 text-general text-right" width="100">
                                <template slot-scope="{ row }">
                                    <p class="text-right">
                                        {{ agregarSeparadoresNumero(row.cantidad) }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="Valor" class-name="f-16 text-general text-center" width="150">
                                <template slot-scope="{ row }">
                                    <p class="text-right">
                                        {{ separadorNumero(row.total) }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="Fecha Creación" class-name="f-16 text-general text-center" width="200">
                                <template slot-scope="{ row }">
                                    <p class="text-right">
                                        {{ formatearFecha(row.created_at) }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="origen" label="Cedis" class-name="f-16 text-general text-left" width="200" />
                            <el-table-column prop="cedis_destino" label="Destino" class-name="f-16 text-general text-left" width="200" />
                        </template>
                    </template>
                </tabla-general>
                <!-- Sin Datos -->
            </div>
            <div v-else class="col-12">
                <sin-datos icon="list" mensaje="No hay datos para mostrar." />
            </div>
        </div>
        <!-- Modales -->
        <modal-informacion-compra ref="modalInformacionCompra" @listar="retirosAbiertos" />
    </section>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import Retiros from "~/services/retiros";
export default {
    components: {
        modalInformacionCompra: () => import('./partials/modalInformacionCompra')
    },
    data(){
        return{
            dataTable: [],
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        masUnaSemana(){
            const haceUnaSemana = moment().subtract(7, 'days')
            return this.dataTable.filter(el => moment(el.created_at).isBefore(haceUnaSemana, 'day')).length
        },
        haceUnaSemana(){
            const haceUnaSemana = moment().subtract(7, 'days')
            return this.dataTable.filter(el => moment(el.created_at).isSameOrAfter(haceUnaSemana, 'day')).length
        },
        ayer(){
            const ayer = moment().subtract(1, 'days')
            return this.dataTable.filter(el => moment(el.created_at).isSame(ayer, 'day')).length
        },
        hoy(){
            const hoy = moment()
            return this.dataTable.filter(el => moment(el.created_at).isSame(hoy, 'day')).length
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        // editar(cliente){
        //     let { id, nombre_cliente, genero, nacimiento, id_direccion, id_avatar, avatar, imagen_firmada, guardar_como, idm_ciudad, idm_estado, idm_pais, direccion, anexo, longitud, latitud } = cliente
        //     let params = { id, nombre_cliente, genero, nacimiento, id_direccion, id_avatar, avatar, imagen_firmada, guardar_como, idm_ciudad, idm_estado, idm_pais, direccion, anexo, longitud, latitud }
        //     this.$refs.editar_cliente.toggle(params)
        // },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.retirosAbiertos()
                    }, 1000);
                }else{
                    await this.retirosAbiertos()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        abrirModal(row){
            this.$refs.modalInformacionCompra.toggle(row.id,1);
        },
        async retirosAbiertos(){
            try {
                let params = {
                    tab: 1,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Retiros.retirosAbiertos(params)
                this.dataTable = data.retiros
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss">
.br-12{
    border-radius: 12px;
}
</style>
