<template>
    <section>
        <div v-if="sugerencias.length" class="row mx-0 px-3 py-3">
            <div class="col bg-white br-8 py-4">
                <div class="row align-items-center mx-0">
                    <div class="col-auto text-general f-18 f-500">
                        Sugerencia de precios sin gestionar
                    </div>
                    <div class="col-auto ml-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Hace más de una semana
                            <b class="ml-3">
                                {{ masUnaSemana }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Hace una semana
                            <b class="ml-3">
                                {{ haceUnaSemana }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Ayer
                            <b class="ml-3">
                                {{ ayer }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Hoy
                            <b class="ml-3">
                                {{ hoy }}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mx-0 align-items-center mt-3">
            <div class="col-auto text-general f-600">
                Solicitudes de cambio de precio de productos en pedidos
            </div>
            <div class="col-xl-3 col-lg-4 ml-auto">
                <el-input v-model="buscar_pedido" placeholder="Buscar Pedido" class="w-100" />
            </div>
            <div class="col-auto">
                <div class="d-middle br-12 border-black">
                    <router-link :to="{name: 'admin.retiros.cambio-precio.lista'}">
                        <el-tooltip placement="bottom" content="Vista de lista" effect="light">
                            <div class="p-2 br-l-12 cr-pointer h" :class="vista == 1? 'bg-general3 text-white' : 'text-general' " @click="vista = 1">
                                <i class="icon-menu f-15" />
                            </div>
                        </el-tooltip>
                    </router-link>
                    <router-link :to="{name: 'admin.retiros.cambio-precio.dashboard'}">
                        <el-tooltip placement="bottom" content="Vista de estadistica" effect="light">
                            <div class="p-2 br-r-12 cr-pointer h" :class="vista == 2? 'bg-general3 text-white' : 'text-general' " @click="vista = 2">
                                <i class="icon-chart-line f-15" />
                            </div>
                        </el-tooltip>
                    </router-link>
                </div>
            </div>
        </div> -->
        <div class="custom-scroll overflow-auto" :style="new RegExp('admin.retiros.cambio-precio').test($route.name) ? '' : 'height:calc(100vh - 206px);'">
            <router-view />
        </div>
    </section>
</template>

<script>
import SugerenciasPrecios from "~/services/cambio_precio_estadisticas";
import moment from 'moment';
import { mapGetters } from 'vuex'
export default {

    data(){
        return{
            vista: 1,
            sugerencias: [],
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        masUnaSemana(){
            const haceUnaSemana = moment().subtract(7, 'days')
            return this.sugerencias.filter(el => moment(el.created_at).isBefore(haceUnaSemana, 'day')).length
        },
        haceUnaSemana(){
            const haceUnaSemana = moment().subtract(7, 'days')
            return this.sugerencias.filter(el => moment(el.created_at).isSameOrAfter(haceUnaSemana, 'day')).length
        },
        ayer(){
            const ayer = moment().subtract(1, 'days')
            return this.sugerencias.filter(el => moment(el.created_at).isSame(ayer, 'day')).length
        },
        hoy(){
            const hoy = moment()
            return this.sugerencias.filter(el => moment(el.created_at).isSame(hoy, 'day')).length
        }
    },
    mounted(){
        this.montar();
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.tablaSugerenciasPrecios()
                    }, 1000);
                }else{
                    await this.tablaSugerenciasPrecios()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoSolicitudes = false
            }
        },
        async tablaSugerenciasPrecios(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await SugerenciasPrecios.tablaSugerenciasPrecios(params)

                this.sugerencias = data.sugerencias
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.br-l-12{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.br-r-12{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.h:hover{
    transition: 0.2s;
    background-color: var(--text-general);
    color: #ffffff;
}
.br-12{ border-radius: 12px; }

</style>
