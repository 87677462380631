<template>
    <section>
        <!-- <div v-if="sugerencias.length" class="row mx-0 px-3 py-3">
            <div class="col bg-white br-8 py-4">
                <div class="row align-items-center mx-0">
                    <div class="col-auto text-general f-18 f-500">
                        Retiros sin confirmar
                    </div>
                    <div class="col-auto">
                        <div class="bg-light-f5 text-general br-8 py-3 br-8 px-3 shadow">
                            Hace más de una semana
                            <b class="ml-3">
                                {{ masUnaSemana }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-light-f5 text-general br-8 py-3 br-8 px-3 shadow">
                            Hace una semana
                            <b class="ml-3">
                                {{ haceUnaSemana }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-light-f5 text-general br-8 py-3 br-8 px-3 shadow">
                            Ayer
                            <b class="ml-3">
                                {{ ayer }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-light-f5 text-general br-8 py-3 br-8 px-3 shadow">
                            Hoy
                            <b class="ml-3">
                                {{ hoy }}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div v-if="sugerencias.length" class="col-12">
            <cargando v-if="cargandoSolicitudes" />
            <tabla-general
            id="cont-scroll"
            alto="calc(100vh - 420px)"
            :data="sugerencias"
            class-header="text-general f-17"
            style="width: 100%"
            >
                <template slot="cabecera-izquierda">
                    <div class="col-auto py-3 text-general f-600">
                        Solicitudes de cambio de precio de productos en pedidos
                    </div>
                </template>
                <template slot="cabecera-derecha">
                    <div class="d-middle br-12 border-black">
                        <router-link :to="{name: 'admin.retiros.cambio-precio.lista'}" class="br-l-8" style="height:32px;width:32px;">
                            <el-tooltip placement="bottom" content="Vista de lista" effect="light">
                                <div class="d-flex align-items-center justify-center br-l-8 cr-pointer h-100 w-100" :class="vista == 1? 'bg-general3 text-white' : 'text-general' " @click="vista = 1">
                                    <i class="icon-menu f-15" />
                                </div>
                            </el-tooltip>
                        </router-link>
                        <router-link :to="{name: 'admin.retiros.cambio-precio.dashboard'}" class="br-r-8" style="height:32px;width:32px;">
                            <el-tooltip placement="bottom" content="Vista de estadistica" effect="light">
                                <div class="d-flex align-items-center justify-center br-r-8 cr-pointer h-100 w-100" :class="vista == 2? 'bg-general3 text-white' : 'text-general' " @click="vista = 2">
                                    <i class="icon-metricas f-15" />
                                </div>
                            </el-tooltip>
                        </router-link>
                    </div>
                </template>
                <el-table-column width="300" label="Producto" fixed>
                    <template slot-scope="{ row }">
                        <div class="row mx-0 align-items-center">
                            <div class="col-auto px-0">
                                <div
                                :class="`${row.estado == 1 ? 'bg-orange' : row.estado == 2 || row.estado == 3 ? 'bg-green' : 'bg-gray'} br-50`"
                                style="width:10px;height:10px;"
                                />
                            </div>
                            <div class="col cr-pointer" @click="verSolicitud(row)">
                                {{ row.producto_desc }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="cantidad" label="Cantidad" width="95" align="right" header-align="center" />
                <el-table-column prop="valor_original" label="Valor Original" width="150" align="right" header-align="center" />
                <el-table-column prop="valor_sugerido" label="Valor Final" width="150" align="right" header-align="center" />
                <el-table-column prop="created_at" label="Fecha solicitud" width="180" />
                <el-table-column prop="id_pedido" label="Pedido" class-name="text-center" width="180" />
                <el-table-column prop="valor_pedido" label="Valor Pedido" width="180" align="right" />
                <el-table-column prop="fecha_pedido" label="Fecha Pedido" width="180" />
                <el-table-column prop="leechero" :label="$config.vendedor" width="180" />
                <el-table-column prop="cedis" label="Cedis" width="180" />
                <el-table-column prop="motivo" label="Motivo" width="180" />
                <el-table-column prop="estado" label="Estado" width="180">
                    <template slot-scope="{ row }">
                        <div class="row mx-0 justify-center align-items-center">
                            <div class="col-auto px-0">
                                <div
                                :class="`${row.estado == 1 ? 'bg-orange' : row.estado == 2 || row.estado == 3 ? 'bg-green' : 'bg-gray'} br-50`"
                                style="width:10px;height:10px;"
                                />
                            </div>
                            <div class="col-auto">
                                {{ row.estado_nombre }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </tabla-general>
        </div>
        <div v-else class="col-12" style="height:calc(100vh - 210px)">
            <sin-datos icon="list" mensaje="No hay datos para mostrar." />
        </div>
        <!-- Partials -->
        <modal-cambio-precio ref="modalCambioPrecio" @update="montar" />
    </section>
</template>

<script>

import SugerenciasPrecios from "~/services/cambio_precio_estadisticas";
import moment from 'moment';
import { mapGetters } from 'vuex'

export default {
    components: {
        modalCambioPrecio: () => import('../partials/modalCambioPrecio')
    },
    data(){
        return {
            sugerencias: [],
            cargandoSolicitudes: true,
            vista: 1
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        masUnaSemana(){
            const haceUnaSemana = moment().subtract(7, 'days')
            return this.sugerencias.filter(el => moment(el.created_at).isBefore(haceUnaSemana, 'day')).length
        },
        haceUnaSemana(){
            const haceUnaSemana = moment().subtract(7, 'days')
            return this.sugerencias.filter(el => moment(el.created_at).isSameOrAfter(haceUnaSemana, 'day')).length
        },
        ayer(){
            const ayer = moment().subtract(1, 'days')
            return this.sugerencias.filter(el => moment(el.created_at).isSame(ayer, 'day')).length
        },
        hoy(){
            const hoy = moment()
            return this.sugerencias.filter(el => moment(el.created_at).isSame(hoy, 'day')).length
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.cargando()
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return

            this.cargando()
            this.montar()
        },
        sugerencias(){
            this.$nextTick(()=>{
                let cursor = document.querySelector('#cont-scroll');
                if( !cursor) return
                cursor.classList.add('cursor-pointer');
                let tabla = document.querySelector('#cont-scroll .el-table__body-wrapper');
                console.log(tabla);
                this.scrollInx(tabla)
            })
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        verSolicitud(row){
            this.$refs.modalCambioPrecio.openSolicitud(row);
        },
        cargando(){
            this.cargandoSolicitudes = true
        },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.tablaSugerenciasPrecios()
                    }, 1000);
                }else{
                    await this.tablaSugerenciasPrecios()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoSolicitudes = false
            }
        },
        async tablaSugerenciasPrecios(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await SugerenciasPrecios.tablaSugerenciasPrecios(params)
                data.sugerencias.map(el => {
                    el.cantidad = this.agregarSeparadoresNumero(el.cantidad)
                    el.valor_original = this.separadorNumero(el.valor_original)
                    el.valor_sugerido = this.separadorNumero(el.valor_sugerido)
                    el.valor_pedido = this.separadorNumero(el.valor_pedido)
                    el.id_pedido = el.id_pedido ? el.id_pedido : '----'
                    el.fecha_solicitud = el.fecha_solicitud ? this.formatearFecha(el.fecha_solicitud) : '----'
                    el.created_at = el.created_at ? this.formatearFecha(el.created_at) : '----'
                    el.fecha_pedido = el.fecha_pedido ? this.formatearFecha(el.fecha_pedido) : '----'
                    el.fecha_pedido_cliente = el.fecha_pedido_cliente ? this.formatearFecha(el.fecha_pedido_cliente) : '----'
                })

                this.sugerencias = data.sugerencias
            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoSolicitudes = false
            }
        },
        scrollInx(element = null){
            console.log(element);
            if(!element) return
            const slider = element
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;

            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');

            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                //console.log(walk);
            });
        },

    }
}
</script>

<style lang="scss" scoped>
.br-12{ border-radius: 12px; }
.br-l-12{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.br-r-12{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.h:hover{
    transition: 0.2s;
    background-color: var(--text-general);
    color: #ffffff;
}
.bg-green{
    background-color: #28D07B;
}
.bg-gray{
    background-color: #DBDBDB;
}

</style>
